<template>
  <div class="h-screen self-center mt-48 w-5/12">
    <img 
      :src="require('assets/images/icons/incorrect.svg')"
      alt="Incorrect"
      class="h-8 w-8 mx-auto"
    >
    <div class="text-center mt-10 space-y-6">
      <h1 class="text-2xl font-bold text-gray-900 md:text-5xl">
        {{ $t('doclineSlotsEmpty.title') }}
      </h1>
      <p class="text-base text-gray-600 md:text-lg">
        {{ $t('doclineSlotsEmpty.subtitle') }}
      </p>
    </div>
  </div>
</template>