import { onBeforeUnmount } from 'vue';
import { createConsumer } from '@rails/actioncable';

const consumer = createConsumer();

export default function useActionCable(channel, callbacks) {
  const subscription = consumer.subscriptions.create(channel, callbacks);

  onBeforeUnmount(() => {
    const index = consumer.subscriptions.subscriptions.indexOf(subscription);
    consumer.subscriptions.subscriptions.splice(index, 1);
  });

  return subscription;
}
