import { Form as VForm } from 'vee-validate';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';
import { VueQueryPlugin } from '@tanstack/vue-query';
import VueCountdown from '@chenfengyuan/vue-countdown';
import * as yup from 'yup';
import es from 'yup-es';
import './css/application.css';
import BaseButton from './components/shared/base-button.vue';
import BaseDisclosure from './components/shared/base-disclosure.vue';
import BaseError from './components/shared/base-error.vue';
import BaseInput from './components/shared/base-input.vue';
import BaseLink from './components/shared/base-link.vue';
import BaseModal from './components/shared/base-modal.vue';
import BaseNotice from './components/shared/base-notice.vue';
import BaseSearch from './components/shared/base-search.vue';
import BaseSelect from './components/shared/base-select.vue';
import BaseNestedSelect from './components/shared/base-nested-select.vue';
import BaseStepperStatus from './components/shared/base-stepper-status.vue';
import BaseCheckbox from './components/shared/base-checkbox.vue';
import BaseCallout from './components/shared/base-callout.vue';
import DoclineStepper from './components/docline/docline-stepper.vue';
import EmergencyRoomStepper from './components/emergency-room/emergency-room-stepper.vue';
import EmergencyTravelStepper from './components/emergency-room/emergency-travel-stepper.vue';
import MediktorDiagnosticator from './components/mediktor/mediktor-diagnosticator.vue';
import MediktorSession from './components/mediktor/mediktor-session.vue';
import DashboardHome from './components/dashboard/dashboard-home.vue';
import DashboardServices from './components/dashboard/dashboard-services.vue';
import DoclineChatStepper from './components/docline/docline-chat-stepper.vue';
import DoclineVideoconsultationStepper from './components/docline/docline-videoconsultation-stepper.vue';
import UserSession from './components/user/user-session.vue';
import UserProfiles from './components/user/user-profiles.vue';
import UserSingleProfile from './components/user/user-single-profile.vue';
import UserDoclineDocuments from './components/user/user-docline-documents.vue';
import UserBookings from './components/user/user-bookings.vue';
import UserBookingsIndex from './components/user/user-bookings-index.vue';
import UserBookingDetail from './components/user/user-booking-detail.vue';
import TheLayout from './components/shared/the-layout.vue';
import TheNavbar from './components/shared/the-navbar.vue';
import Locales from './locales/locales';
yup.setLocale(es);
const i18n = createI18n({ legacy: false, locale: 'es', fallbackLocale: 'es', messages: Locales.messages });
const globalComponents = { BaseButton, BaseDisclosure, BaseError, BaseInput, BaseLink, BaseModal, BaseNotice, BaseSearch, BaseSelect, BaseNestedSelect, BaseStepperStatus, BaseCheckbox, BaseCallout, TheLayout, TheNavbar, InlineSvg, VForm };
document.addEventListener('DOMContentLoaded', () => { const app = createApp({ components: { DoclineStepper, DoclineChatStepper, DoclineVideoconsultationStepper, EmergencyRoomStepper, EmergencyTravelStepper, MediktorDiagnosticator, MediktorSession, DashboardHome, DashboardServices, UserSession, UserProfiles, UserSingleProfile, UserDoclineDocuments, UserBookings, UserBookingsIndex, UserBookingDetail } }); Object.entries(globalComponents).forEach(([name, component]) => { app.component(name, component); }); app.component(VueCountdown.name, VueCountdown); app.use(i18n); app.use(VueQueryPlugin); app.mount('#vue-app'); return app; });
