import { zonedTimeToUtc, formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import es from 'date-fns/locale/es';
function getTimezone(defaultTimezone = 'America/Santiago') { const intlFormatter = Intl.DateTimeFormat; const resolvedOptions = intlFormatter().resolvedOptions(); const timezone = resolvedOptions.timeZone || defaultTimezone; return timezone; }
function zonedTimeToUtcString({ date, timezone = getTimezone() }) { return zonedTimeToUtc(date, timezone).toUTCString(); }
function formatTime({ date, timezone = getTimezone(), pattern = 'hh:mm a' }) { const dateToFormat = utcToZonedTime(date, timezone); return formatInTimeZone(dateToFormat, timezone, pattern); }
function getAMPM({ date, timezone = getTimezone() }) { return formatTime({ date, timezone, pattern: 'a' }); }
function formatDate({ date, timezone = getTimezone(), pattern = 'dd MMMM' }) { const dateToFormat = utcToZonedTime(date, timezone); return formatInTimeZone(dateToFormat, timezone, pattern, { locale: es }); }
function formatDateTime({ date, timezone = getTimezone(), pattern = 'dd MMMM yyyy hh:mm a' }) { const dateToFormat = utcToZonedTime(date, timezone); return formatInTimeZone(dateToFormat, timezone, pattern, { locale: es }); }
function formatDayOfWeek({ date, timezone = getTimezone() }) { const dateToFormat = utcToZonedTime(date, timezone); return formatInTimeZone(dateToFormat, timezone, 'EEEE', { locale: es }); }
export { zonedTimeToUtcString, getTimezone, formatTime, formatDate, formatDateTime, formatDayOfWeek, getAMPM };
